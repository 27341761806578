import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { faEdit, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../components/AuthContext ';

const SereniAi = () => {
  // State management
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [conversationId, setConversationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingCountdown, setRecordingCountdown] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [botName, setBotName] = useState("");
  const [tempBotName, setTempBotName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [isNameLoading, setIsNameLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  
  // Refs
  const chatContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const recognitionRef = useRef(null);
  const countdownIntervalRef = useRef(null);
  const { token } = useAuth();

  // Fetch initial bot name
  useEffect(() => {
    const fetchInitialBotName = async () => {
      setIsNameLoading(true);
      try {
        const response = await fetch('https://serenimindbackend.onrender.com/api/bot-settings/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) throw new Error('Failed to fetch bot name');
  
        const data = await response.json();
        setBotName(data.bot_name);
        setTempBotName(data.bot_name);
      } catch (error) {
        console.error('Error fetching bot name:', error);
      } finally {
        setIsNameLoading(false);
      }
    };
  
    fetchInitialBotName();
  }, [token]);

  // Set initial welcome message
  useEffect(() => {
    setMessages([
      { text: `Hello! I'm ${botName}, your personal mental health assistant. How can I help you today?`, isBot: true }
    ]);

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, [botName]);

  // Auto-scroll chat
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Bot name update handler
  const updateBotName = async (newName) => {
    try {
      const response = await fetch('https://serenimindbackend.onrender.com/api/bot-settings/', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ bot_name: newName }),
      });

      if (!response.ok) throw new Error('Failed to update bot name');

      const data = await response.json();
      return data.bot_name;
    } catch (error) {
      console.error('Error updating bot name:', error);
      throw error;
    }
  };

  const handleNameUpdate = async () => {
    if (tempBotName.trim() === '') {
      setTempBotName(botName);
      setIsEditing(false);
      return;
    }
  
    setIsSaving(true);
    try {
      setUpdateError(null);
      const updatedName = await updateBotName(tempBotName);
      setBotName(updatedName);
      setTempBotName(updatedName);
      window.location.reload();
      setIsEditing(false);
    } catch (error) {
      setUpdateError('Failed to update bot name. Please try again.');
      setTempBotName(botName);
    } finally {
      setIsSaving(false);
    }
  };

  // Loading spinner component
const LoadingSpinner = () => (
  <div className="animate-spin w-4 h-4">
    <svg className="w-full h-full text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
);

  // Message handling
  const handleSendMessage = async (message) => {
    setMessages(prev => [...prev, { text: message, isBot: false }]);
    setIsLoading(true);
    try {
      // Log token and headers for debugging
      console.log('Token being sent:', token);
      console.log('Headers being sent:', {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      });
  
      const response = await fetch('https://serenimindbackend.onrender.com/api/chat/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Ensure `token` is defined and valid
        },
        body: JSON.stringify({
          message: message,
          session_id: conversationId,
          bot_name: 'SereniAI'  // Add this line if necessary
        }),
      });
  
      const data = await response.json();
      setMessages(prev => [...prev, { text: data.response, isBot: true }]);
      setConversationId(data.conversation_id);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, { 
        text: "Sorry, I'm having trouble connecting right now. Please try again later.", 
        isBot: true, 
        isError: true 
      }]);
    } finally {
      setIsLoading(false);
    }
  };
  

  // Image handling
  const handleMoodDetection = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
  
    setMessages((prev) => [
      ...prev,
      {
        text: "Analyzing your mood from the image...",
        isBot: true,
      },
    ]);
  
    setIsLoading(true);
    try {
      const response = await fetch('https://serenimindbackend.onrender.com/api/detect-mood/', {
        method: 'POST',
        body: formData,
      });
  
      const data = await response.json();
      if (response.ok) {
        setMessages((prev) => [
          ...prev,
          {
            text: `Based on your facial expression, you appear to be ${data.mood}. Would you like to talk about what's on your mind?`,
            isBot: true,
          },
        ]);
      } else {
        throw new Error(data.error || 'Mood detection failed');
      }
    } catch (error) {
      console.error('Error detecting mood:', error);
      setMessages((prev) => [
        ...prev,
        {
          text: "Sorry, I couldn't analyze your mood. Please try again later.",
          isBot: true,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };
  

  // Voice recording handlers
  const startVoiceRecording = () => {
    if (isRecording) {
      stopVoiceRecording();
      return;
    }

    if ('webkitSpeechRecognition' in window) {
      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.continuous = false;
      recognitionRef.current.interimResults = false;

      setRecordingCountdown(3);
      
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }

      countdownIntervalRef.current = setInterval(() => {
        setRecordingCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownIntervalRef.current);
            try {
              recognitionRef.current.start();
            } catch (error) {
              console.error('Speech recognition error:', error);
              setMessages(prev => [...prev, { 
                text: "There was an error starting voice recording. Please try again.", 
                isBot: true,
                isError: true
              }]);
            }
            return null;
          }
          return prev - 1;
        });
      }, 1000);

      recognitionRef.current.onstart = () => {
        setIsRecording(true);
        setMessages(prev => [...prev, { 
          text: "🎤 Voice recording started. Speak clearly...", 
          isBot: true 
        }]);
      };

      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setInputText(transcript);
        setMessages(prev => [...prev, { 
          text: "Voice recording completed! You can edit the text before sending.", 
          isBot: true 
        }]);
      };

      recognitionRef.current.onend = () => {
        setIsRecording(false);
        recognitionRef.current = null;
      };

      recognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsRecording(false);
        setMessages(prev => [...prev, { 
          text: "Sorry, there was an error with voice recording. Please try again.", 
          isBot: true,
          isError: true
        }]);
        recognitionRef.current = null;
      };
    } else {
      alert('Speech recognition is not supported in your browser.');
    }
  };

  const stopVoiceRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    setRecordingCountdown(null);
    setIsRecording(false);
  };

  // Event handlers
  const handleVoiceButtonClick = () => {
    if (isRecording) {
      stopVoiceRecording();
    } else {
      startVoiceRecording();
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const imageURL = URL.createObjectURL(file);
      // Add the image to the chat messages
      setMessages((prev) => [
        ...prev,
        {
          text: null, // No text for image message
          image: imageURL, // Set the image URL
          isBot: false,
        },
      ]);
      handleMoodDetection(file); // Process the image for mood detection
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      // Add the image to the chat messages
      setMessages((prev) => [
        ...prev,
        {
          text: null, // No text for image message
          image: imageURL, // Set the image URL
          isBot: false,
        },
      ]);
      handleMoodDetection(file); // Process the image for mood detection
    }
  };
  

  const handleInputChange = (e) => setInputText(e.target.value);
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (inputText.trim()) {
      handleSendMessage(inputText);
      setInputText("");
    }
  };

  // Render functions
  const renderMessage = (message) => (
    <div>
      {message.image && (
        <img 
          src={message.image} 
          alt="Uploaded for mood analysis" 
          className="max-w-xs rounded-lg mb-2"
        />
      )}
      {message.text.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          {index !== message.text.split('\n').length - 1 && <br />}
        </span>
      ))}
    </div>
  );

  const renderBotNameSection = () => (
    <div className="flex items-center justify-between p-4 border-b">
      <div className="flex items-center space-x-2">
        {isNameLoading ? (
          <div className="flex items-center space-x-2">
            <div className="h-7 w-32 bg-gray-200 animate-pulse rounded"></div>
            <div className="h-7 w-7 bg-gray-200 animate-pulse rounded-full"></div>
          </div>
        ) : (
          <>
            <h1 className="text-xl font-semibold">Chat with {botName}</h1>
            <button
              onClick={() => setIsEditing(true)}
              className="p-1.5 text-gray-500 hover:text-green-500 hover:bg-gray-100 rounded-full transition-all duration-200"
              title="Edit bot name"
            >
              <FontAwesomeIcon icon={faEdit} className="w-4 h-4" />
            </button>
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex min-h-screen bg-gradient-to-b from-green-50 to-white">
      {/* Side Navigation (unchanged) */}
      <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
      <div className="p-6">
       <nav>
         <ul className="space-y-2">
           <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
           <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
           </button></li>
           <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
           <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
           </button></li>
           <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
           <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
           </button></li>
           <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
           <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
           </button></li>
           <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
           <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
           </button></li>
         </ul>
       </nav>
      </div>
      
      </div>
      {/* Main Chat Interface */}
      <div className="flex-1 flex flex-col max-w-4xl mx-auto px-1 py-1 md:py-4">
        <div className="bg-white rounded-2xl shadow-lg flex flex-col h-[80vh]"
             onDragEnter={handleDrag}
             onDragLeave={handleDrag}
             onDragOver={handleDrag}
             onDrop={handleDrop}>
          
          {/* Drag & Drop Overlay */}
          {dragActive && (
            <div className="absolute inset-0 bg-green-50 bg-opacity-90 flex items-center justify-center rounded-2xl z-50">
              <div className="text-center p-6 rounded-lg">
                <div className="text-4xl mb-2">📸</div>
                <p className="text-green-600 font-medium">Drop your image here for mood analysis</p>
              </div>
            </div>
          )}

          {/* Chat Header */}
          <div className="flex space-x-3 mb-4 pb-1 pl-6 pt-1 rounded-t-lg bg-[#d1f0de]">
        <h1 className="text-xl font-bold">Chat with {botName}</h1>
        <button
          onClick={() => setIsEditing(true)}
          className="text-sm text-gray-500 hover:underline flex items-center"
        >
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
        </button>
      </div>

      {/* Modal */}
      {isEditing && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-xl shadow-xl p-6 w-96 transform transition-all duration-200 scale-100">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-800">Customize Bot Name</h2>
        <button
          onClick={() => {
            setIsEditing(false);
            setTempBotName(botName);
            setUpdateError(null);
          }}
          className="p-1.5 text-gray-500 hover:text-red-500 hover:bg-red-50 rounded-full transition-all duration-200"
          disabled={isSaving}
        >
          <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
        </button>
      </div>
      
      <div className="space-y-4">
        <div>
          <label htmlFor="botName" className="block text-sm font-medium text-gray-700 mb-1">
            Bot Name
          </label>
          <div className="relative">
            <input
              id="botName"
              type="text"
              value={tempBotName}
              onChange={(e) => setTempBotName(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent outline-none transition-all duration-200"
              placeholder="Enter bot name..."
              disabled={isSaving}
            />
          </div>
        </div>

        {updateError && (
          <div className="bg-red-50 text-red-600 px-4 py-2 rounded-lg text-sm">
            {updateError}
          </div>
        )}

        <div className="flex justify-end space-x-3 pt-4">
          <button
            onClick={() => {
              setIsEditing(false);
              setTempBotName(botName);
              setUpdateError(null);
            }}
            className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-all duration-200"
            disabled={isSaving}
          >
            Cancel
          </button>
          <button
            onClick={handleNameUpdate}
            disabled={isSaving}
            className={`px-4 py-2 text-white bg-green-500 hover:bg-green-600 rounded-lg flex items-center justify-center space-x-2 transition-all duration-200 min-w-[100px] ${
              isSaving ? 'opacity-75 cursor-not-allowed' : ''
            }`}
          >
            {isSaving ? (
              <LoadingSpinner />
            ) : (
              <>
                <FontAwesomeIcon icon={faCheck} className="w-4 h-4" />
                <span>Save</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  </div>
)}

    {/* Chat Messages */}
    <div className="flex flex-col overflow-auto space-y-2 p-4 h-full" ref={chatContainerRef}>
  {/* Existing Messages */}
  {messages.map((message, index) => (
    <div
      key={index}
      className={`flex items-start ${message.isBot ? 'justify-start' : 'justify-end'}`}
    >
      <div
        className={`rounded-lg p-3 max-w-xs text-sm ${
          message.isBot ? 'bg-gray-100 text-gray-800' : 'bg-green-500 text-white'
        }`}
      >
        {message.text && <p>{message.text}</p>}
        {message.image && (
          <img
            src={message.image}
            alt="Uploaded"
            className="w-32 h-32 object-cover rounded-lg mt-2"
          />
        )}
      </div>
    </div>
  ))}
  
  {/* Loading Animation */}
  {isLoading && (
    <div className="flex justify-start mb-4">
      <div className="bg-gray-50 text-gray-800 max-w-[80%] px-4 py-3 rounded-2xl">
        <div className="flex items-center space-x-2">
          <div className="animate-pulse flex space-x-1">
            <div className="w-2 h-2 bg-gray-400 rounded-full"></div>
            <div className="w-2 h-2 bg-gray-400 rounded-full"></div>
            <div className="w-2 h-2 bg-gray-400 rounded-full"></div>
          </div>
          <span className="text-sm text-gray-500">{botName} is thinking...</span>
        </div>
      </div>
    </div>
  )}
</div>


          {/* Recording Countdown Overlay */}
          {recordingCountdown && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-2xl">
              <div className="text-6xl text-white font-bold animate-pulse">
                {recordingCountdown}
              </div>
            </div>
          )}

          {/* Input Area */}
          <div className="border-t border-gray-100 p-4">
            <div className="flex items-center gap-2 mb-2">
              {/* Mood Detection Button */}
              <div className="relative group">
                <button
                  onClick={() => fileInputRef.current.click()}
                  className="p-2 text-gray-500 hover:text-green-500 hover:bg-gray-50 rounded-full transition-colors"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </button>
                <div className="absolute bottom-full left-[80px] transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                  Upload or drop an image for mood analysis
                </div>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept="image/*"
                className="hidden"
              />

              {/* Voice Input Button with Tooltip */}
              <div className="relative group">
    <button
      onClick={handleVoiceButtonClick}
      className={`p-2 hover:bg-gray-50 rounded-full transition-colors ${
        isRecording ? 'text-red-500 animate-pulse' : 'text-gray-500 hover:text-green-500'
      }`}
      disabled={recordingCountdown !== null}
    >
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
      </svg>
    </button>
    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
      {isRecording ? 'Click to stop recording' : 'Click to start voice recording'}
    </div>
  </div>
            </div>

            {/* Message Input Area */}
            <div className="flex items-center bg-gray-50 rounded-xl px-4 py-2">
              <textarea
                value={inputText}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder={`Type your message to ${botName}...`}
                className="flex-1 bg-transparent border-none text-sm focus:outline-none resize-none py-2 min-h-[20px] max-h-[120px]"
                rows="1"
                disabled={isRecording}
              />
              <button 
                onClick={sendMessage}
                className={`ml-2 bg-green-500 text-white rounded-full p-2 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition-colors ${
                  isLoading || isRecording ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isLoading || isRecording}
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                </svg>
              </button>
            </div>

            {/* Recording Status */}
            {isRecording && (
              <div className="mt-2 text-center">
                <span className="text-sm text-red-500 flex items-center justify-center">
                  <span className="animate-pulse mr-2">●</span>
                  Recording... Click the microphone again to stop
                </span>
              </div>
            )}

            {/* Feedback Message */}
            <p className="text-xs text-gray-400 mt-2 text-center">
              {botName} can make mistakes. Please provide 
              <Link to="/feedback" className="text-green-500 hover:underline ml-1">feedback</Link>.
            </p>
          </div>
          {updateError && (
            <p className="text-sm text-red-500 mt-1">{updateError}</p>
            )}
        </div>
      </div>
    </div>
  );
};

export default SereniAi;